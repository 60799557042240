const LeadService = {
  async saveLead(formData) {
    try {
      let url = '/api/lead';
      let method = 'POST';
      const profileLogged = JSON.parse(localStorage.getItem('profile'));
      const { accountIdentifier } = profileLogged;
      console.log(formData);
      if (formData.identifier !== undefined) {
        url = '/api/lead/' + formData.identifier;
        method = 'PUT';
        delete formData.profile;
      }

      const updatedData = {
        ...formData,
        leadSource: formData.leadSource.value,
        profileIdentifier: profileLogged.identifier,
        accountIdentifier: accountIdentifier
      };
      console.log(updatedData);
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedData)
      });
      if (response.ok) {
        const newLead = await response.json();
        console.log('Lead has been updated:', newLead);
        return newLead;
      }
    } catch (error) {
      console.log('Error saving Lead', error);
    }
  },
  async retrieveAllLeadByAccount() {
    try {
      const { accountIdentifier } = JSON.parse(localStorage.getItem('profile'));
      console.log(accountIdentifier);
      const response = await fetch('/api/lead/' + accountIdentifier);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Lead', error);
      throw error;
    }
  },
  async retrieveAllLeadByProfile() {
    try {
      const { identifier } = JSON.parse(localStorage.getItem('profile'));
      console.log(identifier);
      const response = await fetch('/api/lead/profile/' + identifier);
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching Lead', error);
      throw error;
    }
  },
  async retrieveLead(identifier) {
    try {
      const response = await fetch('/api/lead/' + identifier);
      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error fetching Lead', error);
      throw error;
    }
  },
  async importLead(row) {
    const { accountIdentifier, identifier } = JSON.parse(
      localStorage.getItem('profile')
    );

    const url = '/api/lead';
    console.log(url);
    const method = 'POST';
    const lead = {
      firstName: row.firstName,
      lastName: row.lastName,
      email: row.email,
      phoneNumber: row.phoneNumber,
      company: row.company,
      leadSource: row.leadSource,
      website: row.website,
      accountIdentifier: accountIdentifier,
      profileIdentifier: identifier,
      comments: row.comments
    };
    console.log(lead);
    try {
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(lead)
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error importing lead', error);
      throw error;
    }
  },
  async deleteLead(identifier) {
    try {
      let url = '/api/lead/' + identifier;
      let method = 'DELETE';
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const newLead = await response.json();
        console.log('Lead has been deleted:', newLead);
      }
    } catch (error) {
      console.log('Error deleting Lead', error);
    }
  }
};

export default LeadService;
