export default [
  {
    identifier: 'a1b2c3d4-e5f6-7890-abcd-1234567890ef',
    name: 'Complete Sales Training',
    targetValue: '100',
    profileIdentifier: '168630b8-ee7f-4a5e-9030-d3a76e856a60',
    deadline: '2025-04-01',
    progress: 50,
    description:
      "Successfully complete the company's internal sales training program.",
    demo: true
  },
  {
    identifier: 'b2c3d4e5-f678-90ab-cdef-2345678901fg',
    name: 'Improve Customer Satisfaction',
    targetValue: '90',
    profileIdentifier: '168630b8-ee7f-4a5e-9030-d3a76e856a60',
    deadline: '2025-05-15',
    progress: 25,
    description:
      'Achieve a 90% customer satisfaction rating in support feedback.',
    demo: true
  },
  {
    identifier: 'c3d4e5f6-7890-abcd-ef12-3456789012gh',
    name: 'Close 5 New Deals',
    targetValue: '5',
    profileIdentifier: '168630b8-ee7f-4a5e-9030-d3a76e856a60',
    deadline: '2025-04-20',
    progress: 0,
    description: 'Secure 5 new clients for the company in the next quarter.',
    demo: true
  },
  {
    identifier: 'd4e5f678-90ab-cdef-1234-56789012hi34',
    name: 'Submit Q1 Report',
    targetValue: '1',
    profileIdentifier: '168630b8-ee7f-4a5e-9030-d3a76e856a60',
    deadline: '2025-03-31',
    progress: 100,
    description: 'Finalize and submit the quarterly financial report on time.',
    demo: true
  },
  {
    identifier: 'e5f67890-abcd-ef12-3456-789012ij56kl',
    name: 'Attend Leadership Workshop',
    targetValue: '1',
    profileIdentifier: '168630b8-ee7f-4a5e-9030-d3a76e856a60',
    deadline: '2025-06-10',
    progress: 50,
    description:
      'Participate in a leadership training session to enhance management skills.',
    demo: true
  },
  {
    identifier: 'f67890ab-cdef-1234-5678-9012lm78no90',
    name: 'Reduce Ticket Resolution Time',
    targetValue: '15',
    profileIdentifier: '168630b8-ee7f-4a5e-9030-d3a76e856a60',
    deadline: '2025-05-01',
    progress: 75,
    description:
      'Reduce average customer ticket resolution time to 15 minutes.',
    demo: true
  },
  {
    identifier: 'g7890abc-def1-2345-6789-012pqrs345tu',
    name: 'Complete 3 Software Updates',
    targetValue: '3',
    profileIdentifier: '168630b8-ee7f-4a5e-9030-d3a76e856a60',
    deadline: '2025-07-01',
    progress: 25,
    description: 'Develop and deploy three major software updates.',
    demo: true
  },
  {
    identifier: 'h890abcd-ef12-3456-7890-12vwxyz56789',
    name: 'Increase Social Media Engagement',
    targetValue: '5000',
    profileIdentifier: '168630b8-ee7f-4a5e-9030-d3a76e856a60',
    deadline: '2025-06-30',
    progress: 0,
    description:
      'Increase LinkedIn post engagements to 5000 within the next three months.',
    demo: true
  },
  {
    identifier: 'i901bcde-f123-4567-8901-234abcd89012',
    name: 'Mentor a New Employee',
    targetValue: '1',
    profileIdentifier: '168630b8-ee7f-4a5e-9030-d3a76e856a60',
    deadline: '2025-04-15',
    progress: 50,
    description: 'Successfully mentor and onboard a new team member.',
    demo: true
  },
  {
    identifier: 'j012cdef-2345-6789-0123-4567efghijkl',
    name: 'Reduce Employee Turnover',
    targetValue: '10',
    profileIdentifier: '168630b8-ee7f-4a5e-9030-d3a76e856a60',
    deadline: '2025-08-01',
    progress: 75,
    description:
      'Decrease employee turnover rate by 10% through improved engagement strategies.',
    demo: true
  }
];
