import { format } from 'date-fns';
import DemoService from '../common/functions/DemoService';
const transformDateWithFormat = (britDate, formatStyle) => {
  const dateString = britDate;
  const date = new Date(dateString);
  const formattedDate = format(date, formatStyle);
  return formattedDate;
};
const calculateDaysBetweenDates = (startDate, endDate) => {
  // Calculate the time difference in milliseconds

  const timeDifference = endDate.getTime() - startDate.getTime();
  console.log(timeDifference);
  // Convert milliseconds to days
  const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24)) + 1;

  return daysDifference;
};
const calculateHolidaysTaken = absences => {
  const requests = absences.filter(abs => abs.status === 'APPROVED');
  let holidayDaysTaken = 0;
  requests.map(r => {
    try {
      const startDate = new Date(r.start);
      const endDate = new Date(r.end);
      console.log(startDate);
      console.log(endDate);

      holidayDaysTaken += calculateDaysBetweenDates(startDate, endDate);
      console.log(holidayDaysTaken);
      +1; // It doesn't work for half day...
    } catch (error) {
      console.log(error);
    }
  });
  return holidayDaysTaken;
};
const convertDateFormat = originalDate => {
  const date = new Date(originalDate);
  const formattedDate = date.toISOString().slice(0, 19);
  return formattedDate;
};
const validateStartDate = formData => {
  const start = new Date(formData.start);
  const currentYear = new Date().getFullYear();
  if (start.getFullYear() < currentYear) {
    throw new Error('Error: Start date must be in current year ' + currentYear);
  }
};
const validateEndDate = formData => {
  const start = new Date(formData.start);
  const end = new Date(formData.end);
  const currentYear = new Date().getFullYear();
  if (end.getFullYear() < currentYear) {
    throw new Error('Error: End date must be in current year ' + currentYear);
  }
  if (start > end) {
    throw new Error('Error: End value must be later than Start');
  }
  return true;
};
const HolidayService = {
  approveHoliday: async function (holidayIdentifier) {
    try {
      let url = '/api/holiday/approve/' + holidayIdentifier;
      let method = 'POST';

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const newContract = await response.json();
        console.log('Holiday has been updated:', newContract);
      }
    } catch (error) {
      console.log('Error saving Contract', error);
    }
  },
  archiveHoliday: async function (identifier) {
    const url = '/api/holiday/' + identifier;
    const method = 'DELETE';
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const json = await response.json();
    return json;
  },
  async saveHoliday(formData) {
    try {
      validateStartDate(formData);
      validateEndDate(formData);
      if (undefined === formData.type || formData.type.value === null) {
        throw new Error('Error: Select a type is required');
      }
      let url = '/api/holiday';
      let method = 'POST';
      const profileLogged = JSON.parse(localStorage.getItem('profile'));
      const { identifier } = profileLogged;

      if (formData.identifier !== undefined) {
        url = '/api/holiday/' + formData.identifier;
        method = 'PUT';
      }
      delete formData.profile;

      const updatedFormData = {
        ...formData,
        profileIdentifier: identifier,
        start: convertDateFormat(formData.start),
        end: convertDateFormat(formData.end),
        type: formData.type.value
      };
      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedFormData)
      });

      if (response.ok) {
        const newHoliday = await response.json();
        console.log('Holiday has been updated:', newHoliday);
      } else {
        throw new Error("You don't have more holiday days");
      }
    } catch (error) {
      console.log('Error saving Holiday', error);
      throw error;
    }
  },
  retrieveHoliday: async function (identifier) {
    try {
      const response = await fetch('/api/holiday/' + identifier);
      const data = await response.json();
      return data;
    } catch (error) {
      console.log('Error retrieving a holiday', error);
    }
  },
  getRandomColor: function () {
    let keepRolling = true;
    while (keepRolling) {
      const red = Math.floor(Math.random() * 256);
      const green = Math.floor(Math.random() * 256);
      const blue = Math.floor(Math.random() * 256);

      // Calculate the perceived brightness using the formula
      // (R * 299 + G * 587 + B * 114) / 1000
      const brightness = (red * 299 + green * 587 + blue * 114) / 1000;

      if (brightness > 150) {
        continue; // Skip colors that are too light
      }

      return `rgb(${red}, ${green}, ${blue})`;
    }
  },
  rejectHoliday: async function (holidayIdentifier) {
    try {
      let url = '/api/holiday/reject/' + holidayIdentifier;
      let method = 'POST';

      const response = await fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const newContract = await response.json();
        console.log('Holiday has been updated:', newContract);
      }
    } catch (error) {
      console.log('Error saving Contract', error);
    }
  },
  retrieveManagementEvents: async function () {
    try {
      const profile = JSON.parse(localStorage.getItem('profile'));
      const response = await fetch('/api/event/profile/' + profile.identifier);
      const managerColour = 'primary';
      const childrenColour = 'success';
      const data = await response.json();
      const events = data.map(h => ({
        extendedProps: {
          status: h.status,
          profile: h.profile,
          identifier: h.identifier,
          authorizedBy: h.authorizedBy,
          profileIdentifier: h.profileIdentifier,
          editButtons:
            h.authorizedBy === null || h.authorizedBy === profile.identifier
        },
        id: h.identifier,
        title:
          h.profile.firstName +
          ' ' +
          h.profile.lastName +
          ' ' +
          transformDate(h.start) +
          ' to ' +
          transformDate(h.end),
        start: transformDateWithFormat(h.start, 'yyyy-MM-dd HH:mm'),
        startTime: transformDateWithFormat(h.start, 'dd MMM, yyyy'),
        end: transformDateWithFormat(h.end, 'yyyy-MM-dd HH:mm'),
        allDay: true,
        endTime: transformDateWithFormat(h.end, 'dd MMM, yyyy'),
        classNames: `border border-2 border-primary bg-100`,
        display: 'background',
        color:
          h.authorizedBy === profile.identifier ? childrenColour : managerColour
      }));

      console.log(events);
      return events;
    } catch (error) {
      // Handle the error
      console.error('There was an error!', error);
      // You may want to throw the error or handle it differently
      throw error;
    }
  },
  retrieveAllHolidayRequests: async function () {
    try {
      const profile = JSON.parse(localStorage.getItem('profile'));
      const response = await fetch(
        '/api/holiday/profile/' + profile.identifier + '/all'
      );
      const managerColour = '#2c7be5';
      const data = await response.json();
      const events = data.map(h => ({
        extendedProps: {
          status: h.status,
          profile: h.profile,
          identifier: h.identifier,
          authorizedBy: h.authorizedBy,
          profileIdentifier: h.profileIdentifier,
          editButtons:
            h.authorizedBy === null || h.authorizedBy === profile.identifier
        },
        id: h.identifier,
        title:
          h.profile.firstName +
          ' ' +
          h.profile.lastName +
          ' ' +
          transformDate(h.start) +
          ' to ' +
          transformDate(h.end),
        start: h.start,
        end: h.end,
        backgroundColor:
          h.authorizedBy === profile.identifier
            ? this.getRandomColor()
            : managerColour,
        textColor: 'white'
      }));
      if (localStorage.getItem('bstDemoMode') === 'true') {
        const eventsWithDemoHolidays = events || [];
        return eventsWithDemoHolidays.concat(
          DemoService.retrieveDemoHolidays()
        );
      }
      console.log(events);
      return events;
    } catch (error) {
      // Handle the error
      console.error('There was an error!', error);
      // You may want to throw the error or handle it differently
      throw error;
    }
  },

  retrieveHolidayRequests: async function () {
    try {
      const profile = JSON.parse(localStorage.getItem('profile'));
      const response = await fetch(
        '/api/holiday/profile/' + profile.identifier
      );
      const managerColour = '#2c7be5';
      const data = await response.json();
      const events = data.map(h => ({
        extendedProps: {
          status: h.status,
          profile: h.profile,
          identifier: h.identifier,
          authorizedBy: h.authorizedBy,
          profileIdentifier: h.profileIdentifier,
          editButtons:
            h.authorizedBy === null || h.authorizedBy === profile.identifier
        },
        id: h.identifier,
        title:
          h.profile.firstName +
          ' ' +
          h.profile.lastName +
          ' ' +
          transformDate(h.start) +
          ' to ' +
          transformDate(h.end),
        start: h.start,
        end: h.end,
        backgroundColor:
          h.authorizedBy === profile.identifier
            ? this.getRandomColor()
            : managerColour,
        textColor: 'white'
      }));
      if (localStorage.getItem('bstDemoMode') === 'true') {
        const eventsWithDemoHolidays = events || [];
        return eventsWithDemoHolidays.concat(
          DemoService.retrieveDemoHolidays()
        );
      }
      return events;
    } catch (error) {
      // Handle the error
      console.error('There was an error!', error);
      // You may want to throw the error or handle it differently
      throw error;
    }
  },
  async retrieveAbsences() {
    const profile = JSON.parse(localStorage.getItem('profile'));

    try {
      const response = await fetch(
        '/api/holiday/profile/' + profile.identifier + '/all'
      );
      const data = await response.json();
      console.log(data);
      if (localStorage.getItem('bstDemoMode') === 'true') {
        const eventsWithDemoHolidays = data || [];
        return eventsWithDemoHolidays.concat(
          DemoService.retrieveDemoHolidays()
        );
      }
      return data;
    } catch (error) {
      console.error('Error fetching users', error);
      throw error;
    }
  },
  async retrieveAbsencesThisYear() {
    const profile = JSON.parse(localStorage.getItem('profile'));

    try {
      const response = await fetch(
        '/api/holiday/profile/' + profile.identifier
      );
      const data = await response.json();
      console.log(data);
      if (localStorage.getItem('bstDemoMode') === 'true') {
        const eventsWithDemoHolidays = data || [];
        return eventsWithDemoHolidays.concat(
          DemoService.retrieveDemoHolidays()
        );
      }
      return data;
    } catch (error) {
      console.error('Error fetching users', error);
      throw error;
    }
  },
  async retrieveAbsencesByProfile(profileIdentifier) {
    try {
      const response = await fetch('/api/holiday/profile/' + profileIdentifier);
      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.error('Error fetching users', error);
      throw error;
    }
  },

  transformDate(britDate) {
    const date = new Date(britDate);
    // Extract day, month, and year
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1
    const year = date.getFullYear();

    // Format the date as dd/MM/yyyy
    const formattedDate = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;
    return formattedDate;
  },
  async getValuesForAbsencesChart(profile, absences) {
    if (!absences || !Array.isArray(absences)) {
      throw new Error('No absences to calculate');
    }

    const daysTaken = await calculateHolidaysTaken(absences);
    const holidayEntitlement =
      null !== profile.contract ? profile.contract.holidayEntitlement ?? 0 : 0;
    const daysRemaining = holidayEntitlement - daysTaken;
    console.log(holidayEntitlement);
    console.log(daysTaken);
    console.log(daysRemaining);
    return [
      { id: 1, value: daysTaken, name: 'Taken', color: 'primary' },
      { id: 2, value: daysRemaining, name: 'Remaining', color: 'info' }
    ];
  }
};
const transformDate = britDate => {
  const date = new Date(britDate);

  // Extract day, month, and year
  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based, so we add 1
  const year = date.getFullYear();

  // Format the date as dd/MM/yyyy
  const formattedDate = `${day.toString().padStart(2, '0')}/${month
    .toString()
    .padStart(2, '0')}/${year}`;
  return formattedDate;
};
export default HolidayService;
