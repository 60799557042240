import Shepherd from 'shepherd.js';

const TourService = {
  retrieveMainTour() {
    const isDarkMode = localStorage.getItem('isDark') === 'true';

    // Define colors based on mode
    const overlayColor = isDarkMode
      ? 'rgba(255, 255, 255, 0.2)'
      : 'rgba(0, 0, 0, 0.6)';
    const highlightColor = isDarkMode
      ? 'rgba(255, 255, 255, 0)'
      : 'rgba(255, 255, 255, 1)';
    const tooltipBg = isDarkMode ? '#1e1e1e' : '#ffffff'; // Dark background for tooltips
    const textColor = isDarkMode ? '#ffffff' : '#000000'; // Text color based on mode
    const style = document.createElement('style');
    style.innerHTML = `
      .shepherd-modal-overlay-container {
        background-color: ${overlayColor} !important;
      }
      .shepherd-highlight {
        box-shadow: 0px 0px 15px 5px ${highlightColor};
      }
      .shepherd-element {
        background-color: ${tooltipBg} !important;
        color: ${textColor} !important;
        border-radius: 8px;
      }
     .shepherd-text {
     color: ${textColor} !important;
     }
      .shepherd-header {
        background-color: ${tooltipBg} !important;
      }
      .shepherd-footer {
        background-color: ${tooltipBg} !important;
      }
    `;
    document.head.appendChild(style);
    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: 'shadow-md bg-white rounded p-3',
        scrollTo: true,
        modalOverlayOpeningPadding: 10, // Adds padding to highlight area
        modalOverlayOpeningRadius: 8, // Adds rounded corners to highlight area
        highlightClass: 'shepherd-highlight' // Custom class for highlighted element
      }
    });

    tour.addStep({
      id: 'step-1',
      text: "Welcome to Bright Staff Tool! 🚀 This quick tour will guide you through the key features to help you manage HR tasks efficiently. You'll learn how to navigate the menu, create schedules, track users, access shared files, and more. Click Next to get started!.",
      attachTo: { element: '#topLogo', on: 'right' },
      buttons: [{ text: 'Next', action: tour.next }]
    });

    tour.addStep({
      id: 'step-2',
      text: 'Use the menu to navigate through BST. Here, you can manage Holidays, track Performance, and oversee Leads Management to streamline HR operations.',
      attachTo: { element: '.flex-column.navbar-nav', on: 'right' },
      buttons: [
        { text: 'Back', action: tour.back },
        { text: 'Next', action: tour.next }
      ]
    });
    tour.addStep({
      id: 'step-3',
      text: 'Here, you’ll find shortcuts to essential features for faster navigation. You can also enable or disable the Guide Help and manage Demo Users from this menu to customize your experience',
      attachTo: { element: '#topNavRightBar', on: 'right' },
      buttons: [
        { text: 'Back', action: tour.back },
        { text: 'Next', action: tour.next }
      ]
    });
    tour.addStep({
      id: 'step-4',
      text: 'Plan and organize work efficiently with Create Schedule. Assign shifts, set availability, and manage team workload with ease.',
      attachTo: {
        element: '.p-0.management-calendar.card-body',
        on: 'bottom'
      },
      buttons: [
        { text: 'Back', action: tour.back },
        { text: 'Next', action: tour.next }
      ]
    });
    tour.addStep({
      id: 'step-5',
      text: "Get a quick overview of who's online and currently working. Monitor activity and ensure seamless collaboration across your team.",
      attachTo: { element: '#activeUsers', on: 'left' },
      buttons: [
        { text: 'Back', action: tour.back },
        { text: 'Next', action: tour.next }
      ]
    });
    tour.addStep({
      id: 'step-6',
      text: 'Stay on top of your tasks with the To-Do List. Add, manage, and track your HR-related tasks in one place to keep your workflow organized and efficient.',
      attachTo: { element: '#todoList', on: 'bottom' },
      buttons: [
        { text: 'Back', action: tour.back },
        { text: 'Next', action: tour.next }
      ]
    });
    tour.addStep({
      id: 'step-7',
      text: 'Post and manage internal job openings effortlessly. Internal Vacancies help you promote career growth within your company.',
      attachTo: { element: '#internalVacancies', on: 'left' },
      buttons: [
        { text: 'Back', action: tour.back },
        { text: 'Next', action: tour.next }
      ]
    });

    tour.addStep({
      id: 'step-8',
      text: 'Access and manage important HR documents in Shared Files. Upload, share, and collaborate on essential resources securely.',
      attachTo: { element: '#shareFiles', on: 'left' },
      buttons: [
        { text: 'Back', action: tour.back },
        {
          text: 'Finish',
          action: () => {
            localStorage.setItem('bstShowGuide', 'false');
            tour.complete();
          }
        }
      ]
    });
    return tour;
  }
};
export default TourService;
