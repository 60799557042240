export default [
  {
    employee: 'Kit Harington',
    identifier: 1,
    reviewDate: '2025-03-28',
    reviewer: 'Sophie Turner'
  },
  {
    employee: 'Peter Dinklage',
    identifier: 2,
    reviewDate: '2025-04-10',
    reviewer: 'Nikolaj Coster'
  },
  {
    employee: 'Alfie Allen',
    identifier: 3,
    reviewDate: '2025-05-05',
    reviewer: 'Liam Cunningham'
  },
  {
    employee: 'Rory McCann',
    identifier: 4,
    reviewDate: '2025-06-15',
    reviewer: 'Michelle Fairley'
  },
  {
    employee: 'Gemma Whelan',
    identifier: 5,
    reviewDate: '2025-07-20',
    reviewer: 'Kit Harington'
  }
];
