import React, { useContext, useState } from 'react';
import { Nav, Tooltip, OverlayTrigger, Button, Modal } from 'react-bootstrap';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import NotificationDropdown from 'components/navbar/top/NotificationDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import QuickActionsDropdown from './QuickActionsDropdown';
import DirectoryService from '../../app/directory/DirectoryService';

const TopNavRightSideNavItem = () => {
  const [modalReferral, setModalReferral] = useState(false);
  const uniqueIDReferral =
    'RP-' + DirectoryService.retrieveLoggedProfile().identifier;
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);
  return (
    <>
      <Nav
        navbar
        className="navbar-nav-icons ms-auto flex-row align-items-center"
        as="ul"
        id="topNavRightBar"
      >
        <Button
          variant="warning"
          size="sm"
          onClick={() => setModalReferral(true)}
          className="desktop-only"
        >
          Referral Program
        </Button>
        <QuickActionsDropdown />
        <Nav.Item as={'li'}>
          <Nav.Link
            className="px-2 theme-control-toggle"
            onClick={() => setConfig('isDark', !isDark)}
          >
            <OverlayTrigger
              key="left"
              placement={isRTL ? 'bottom' : 'left'}
              overlay={
                <Tooltip id="ThemeColor">
                  {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
                </Tooltip>
              }
            >
              <div className="theme-control-toggle-label">
                <FontAwesomeIcon
                  icon={isDark ? 'sun' : 'moon'}
                  className="fs-0"
                />
              </div>
            </OverlayTrigger>
          </Nav.Link>
        </Nav.Item>

        <NotificationDropdown />
        <ProfileDropdown />
      </Nav>
      {modalReferral && (
        <Modal
          show={modalReferral}
          onHide={() => setModalReferral(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Referral Program
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Refer & Save: Get 20% Off! 🎉</h4>
            <p>
              This is your unique ID: <b>{uniqueIDReferral}</b>.<br />
              For every new customer you refer, you’ll receive 20% off your next
              month's subscription – and they’ll get 20% off too! It’s a
              win-win!
            </p>
            <h5>How it works:</h5>
            <ul>
              <li>Share your unique referral link with friends.</li>
              <li>When they sign up and subscribe, both of you get 20% off.</li>
              <li> Keep referring and keep saving!</li>
              <li>Start sharing now and enjoy the benefits! 🚀</li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModalReferral(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default TopNavRightSideNavItem;
