import React from 'react';
import { Importer, ImporterField } from 'react-csv-importer';
import useHeaderGenerator from '../common/functions/useHeaderGenerator';
// include the widget CSS file whichever way your bundler supports it
import 'react-csv-importer/dist/index.css';
import csv_file from 'assets/csv_leads_template.csv';
import LeadService from './LeadService';
const ImportLeadsPage = () => {
  /*  
  const goToMyAppNextPage = () => {
    console.log('goToMyNextPage');
  };
  const prepMyAppForIncomingData = () => {
    console.log('prepMyAppForIncomingData');
  };
  const showMyAppToastNotification = () => {
    console.log('showMyAppToastNotification');
  };*/
  const myAppMethod = row => {
    console.log(row);
    LeadService.importLead(row)
      .then(lead => {
        console.log(lead);
      })
      .catch(error => console.log(error));
  };
  const buttonsConf = [
    {
      name: 'Download a CSV example',
      type: 'submit',
      variant: 'info',
      onClick: () => {
        const fileUrl = csv_file;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'csv_leads_template.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  ];
  const importUsersDescription =
    'Quickly import leads using a CSV file, streamlining the process of adding or updating multiple users at once for efficient data management.';
  const headerGenerated = useHeaderGenerator(
    'Import Leads',
    importUsersDescription,
    buttonsConf
  );
  return (
    <>
      {headerGenerated}
      <Importer
        dataHandler={async (rows, { startIndex }) => {
          console.log(startIndex);
          let row = {};
          // required, may be called several times
          // receives a list of parsed objects based on defined fields and user column mapping;
          // (if this callback returns a promise, the widget will wait for it before parsing more data)
          for (row of rows) {
            await myAppMethod(row);
          }
        }}
        defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
        restartable={false} // optional, lets user choose to upload another file when import is complete
        // onStart={({ file, preview, fields, columnFields }) => {
        // optional, invoked when user has mapped columns and started import
        // prepMyAppForIncomingData();
        // }}
        // onComplete={({ file, preview, fields, columnFields }) => {
        // optional, invoked right after import is done (but user did not dismiss/reset the widget yet)
        // showMyAppToastNotification();
        // }}
        // onClose={({ file, preview, fields, columnFields }) => {
        // optional, if this is specified the user will see a "Finish" button after import is done,
        // which will call this when clicked
        // goToMyAppNextPage();
        // }}

        // CSV options passed directly to PapaParse if specified:
        // delimiter={...}
        // newline={...}
        // quoteChar={...}
        // escapeChar={...}
        // comments={...}
        // skipEmptyLines={...}
        // delimitersToGuess={...}
        // chunkSize={...} // defaults to 10000
        // encoding={...} // defaults to utf-8, see FileReader API
      >
        <ImporterField name="firstName" label="First Name" />
        <ImporterField name="lastName" label="Last Name" />
        <ImporterField name="email" label="Email" />
        <ImporterField name="company" label="Company Name" optional />
        <ImporterField name="phoneNumber" label="Mobile" optional />
        <ImporterField name="website" label="Address" optional />
        <ImporterField name="leadSource" label="Lead Source" />
        <ImporterField name="comments" label="Comments" />
      </Importer>
    </>
  );
};

export default ImportLeadsPage;
