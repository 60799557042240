import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import Background from 'components/common/Background';
import corner1 from 'assets/img/icons/spot-illustrations/corner-3.png';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor } from 'helpers/utils';
import { tooltipFormatter } from 'helpers/echart-utils';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';

import { CanvasRenderer } from 'echarts/renderers';
function getRandomColor(index) {
  const colors = [
    '#FF5733', // Red-Orange
    '#33FF57', // Green
    '#5733FF', // Blue-Violet
    '#F1C40F', // Yellow
    '#1ABC9C', // Teal
    '#E74C3C', // Bright Red
    '#8E44AD', // Purple
    '#3498DB', // Sky Blue
    '#2ECC71', // Emerald Green
    '#F39C12' // Orange
  ];
  return colors[index % colors.length];
}
// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);
const ProgressBarWidget = ({ goals }) => {
  const names = goals.map(x => x.name);

  const data = goals.map((x, index) => ({
    value: x.progress,
    itemStyle: { color: getRandomColor(index) }
  }));
  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    xAxis: {
      type: 'value',
      boundaryGap: '0%',
      axisLine: {
        show: true,
        lineStyle: {
          color: getColor('300')
        }
      },
      axisTick: { show: true },
      axisLabel: {
        color: getColor('500')
      },
      splitLine: {
        show: false
      },
      min: 10
    },
    yAxis: {
      type: 'category',
      data: names,
      boundaryGap: '0%',
      axisLabel: {
        formatter: value => value.substring(0, 16),
        show: true,
        color: getColor('500'),
        margin: 10
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('200')
        }
      },
      axisTick: { show: true },
      axisLine: {
        lineStyle: {
          color: getColor('300')
        }
      }
    },
    series: [
      {
        type: 'bar',
        name: 'Total',
        data,
        lineStyle: { color: getColor('primary') },
        itemStyle: {
          color: getColor('success'),
          borderRadius: [0, 3, 3, 0]
        },
        animationDuration: 2000, // Bars animate in 2 seconds
        animationEasing: 'elasticOut', // Smooth bouncing effect
        animationDelay: function (idx) {
          return idx * 150; // Delays each bar slightly
        },
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true
        }
      }
    ],
    grid: { right: '3%', left: '10%', bottom: '10%', top: '5%' }
  });
  return (
    <>
      <Card>
        <Background image={corner1} className="rounded-soft bg-card" />
        <Card.Header>
          <h5 className="display-6 mb-0">Progress</h5>
        </Card.Header>
        <Card.Body>
          <ReactEChartsCore
            echarts={echarts}
            option={getOption()}
            style={{ height: '18.75rem' }}
          />
        </Card.Body>
      </Card>
    </>
  );
};

ProgressBarWidget.propTypes = {
  data: PropTypes.shape({
    map: PropTypes.func
  }),
  goals: PropTypes.shape({
    map: PropTypes.func
  })
};

export default ProgressBarWidget;
