import React from 'react';

import useHeaderGenerator from '../common/functions/useHeaderGenerator';
import UserList from './UserList';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import DirectoryService from './DirectoryService';
import { useState } from 'react';
import { utils, writeFile } from 'xlsx';

const UsersLandingPage = () => {
  const navigate = useNavigate();
  const [exportToExcelData, setExportToExcelData] = useState([]);
  useEffect(() => {
    DirectoryService.retrieveUsersByAccount()
      .then(data => {
        const exportToExcelData = data.map(x => ({
          identifier: x.identifier,
          'first name': x.firstName,
          'last name': x.lastName,
          email: x.email,
          dob: x.dob,
          address: x.address,
          'employee number': x.employeeNumber,
          'job type': x.contract.jobType,
          location: x.contract.location
        }));
        setExportToExcelData(exportToExcelData);
      })
      .catch(error => console.log(error));
  }, []);
  const buttonsConf = [
    {
      name: 'Create a new user',
      type: 'submit',
      variant: 'primary',
      onClick: () => {
        console.log('Create a new Goal');
        navigate('/social/directory/user-details');
      }
    },
    {
      name: 'Import users',
      type: 'submit',
      variant: 'primary',
      onClick: () => {
        console.log('Import users');
        navigate('/social/directory/user/import');
      }
    },
    {
      name: 'Export to Excel',
      type: 'button',
      variant: 'outline-success',
      onClick: () => {
        const data = exportToExcelData;
        const name = 'Users';
        let wb = utils.book_new();
        let ws = utils.json_to_sheet(data);
        utils.book_append_sheet(wb, ws, name);
        const fileName = name + '.xlsx';
        writeFile(wb, fileName);
      }
    }
  ];
  const userLandingPageDescription =
    'Centralize all employee data with our comprehensive Employee Management module. Easily manage personal information, track employment history, and monitor performance. This module empowers HR teams to maintain up-to-date records, generate insightful reports, and ensure compliance with ease, all through a seamless and secure interface.';
  const headerGenerated = useHeaderGenerator(
    'Users',
    userLandingPageDescription,
    buttonsConf
  );
  return (
    <>
      {headerGenerated}
      <UserList></UserList>
    </>
  );
};
export default UsersLandingPage;
